<template>
    <!-- Modal suppression cheval -->
    <b-modal :id="'modal-confirm-'+id" :ref="'modal-confirm-'+id" @hidden="handleClose">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
            <h2 class="mx-auto my-auto">{{ text_title }}</h2>
        </template>
        <template slot="default">
            <b>{{ text_question }}</b>
            <br/>{{ text_message }}
        </template>
        <template slot="modal-footer" slot-scope="{ ok, cancel}" class="justify-content-center">
            <b-button variant="primary" v-if="text_button_ok" @click="callback_validation">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                <font-awesome-icon v-else :icon="icon" class="mr-1"/>{{ text_button_ok }}
            </b-button>
            <b-button v-if="callback_accept" variant="primary" @click="callback_accept">
                <font-awesome-icon :icon="icon_accept" class="mr-1"/>{{ text_button_accept }}
            </b-button>
            <b-button @click="cancel()">
                <font-awesome-icon :icon="['fal', 'times']" class="mr-1"/> {{ text_button_cancel }}
            </b-button>
        </template>
    </b-modal>
</template>


<script type="text/javascript">
	export default {
        props:[
            'icon', // Icone (ex: ['fal', 'long-arrow-left'])
            'icon_accept', // Icone (ex: ['fal', 'long-arrow-left'])
            'text_title',// Titre (ex: $t('modal.acte.suppression_acte'))
            'text_question',// Texte de la question (ex: $t('modal.acte.confirmation_suppression'))
            'text_message',// Texte du message explicatif associé (ex: $t('modal.horse.avertissement_suppression'))
            'text_button_ok',// Texte du bouton OK (ex: $t('global.supprimer'))
            'text_button_cancel',// Texte du bouton Annuler (ex: $t('global.annuler'))
            'text_button_accept',// Texte du bouton Accepter (ex: $t('global.loose_modif')) => par ex, pour une modal de confirmation de fermetture sans sauvegarder le travail en cours
            'callback_validation',// Fonction appelée lors du clic sur OK
            'callback_accept',// Fonction appelée lors du clic sur Accepter
            'callback_cancel',// Fonction appelée lors du clic sur Accepter
            'processing'
		],
        computed:{
            id: function() { // ID unique pour éviter les conflits
                return this._uid
            }
        },
        methods: {
            openModal() {
                this.$bvModal.show('modal-confirm-'+this.id)
            },

            closeModal() {
                this.$bvModal.hide('modal-confirm-'+this.id)
            },

            handleClose() {
                if(this.callback_cancel) {
                    this.callback_cancel()
                }
            }
        }
	}
</script>
